import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "@tt/vue-auth0";
import i18n from "@/plugins/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/orders",
    // name: "dashboard",
    // menuTitle: "Dashboard",
    // menuIcon: "mdi-home-outline",
    // showIcon: false,
    // component: () => import("@/views/Dashboard"),
    active: false
  },
  {
    path: "/orders",
    name: "orders",
    menuTitle: i18n.t("orders.overview.title"),
    menuIcon: "mdi-shopping-outline",
    showIcon: false,
    component: () => import("@/views/OrdersOverview"),
    active: true
  },
  {
    path: "/orders/:reference",
    name: "orders/reference",
    component: () => import("@/views/OrdersOverview"),
    active: false
  },
  {
    path: "/pickup-in-store",
    name: "pickup",
    menuTitle: i18n.t("pickup.overview.title"),
    menuIcon: "mdi-package-up",
    showIcon: false,
    component: () => import("@/views/PickupOrdersOverview"),
    active: true
  },
  {
    path: "/import",
    name: "import",
    menuTitle: i18n.t("imports.overview.title"),
    menuIcon: "mdi-shopping-outline",
    showIcon: false,
    component: () => import("@/views/ImportOverview"),
    active: true
  },
  {
    path: "/access-demo",
    name: "access-demo",
    menuTitle: 'Access Demo',
    menuIcon: "mdi-check-circle-outline",
    showIcon: false,
    component: () => import("@/views/AccessDemo"),
    active: true
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeEach(authGuard);

export default router;
