/**
 * Important:
 * This is a helper to hide unavailable functionality to the user.
 * By no means this is meant to be a security feature.
 * The actual validation for access to a given API-call is done by the API-endpoint itself.
 * This follows the old mantra: trust nothing from client-side!
 */
import Vue from "vue";
import VueRbac from "@tt/vue-rbac";

const options = {
  adminRole: "ADMIN",
  userIdentifierProperty: "tt_identifier",
  userPermissionsProperty: "tt_permissions"
}

Vue.use(VueRbac, options);
